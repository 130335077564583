import React from "react"

import styled from 'styled-components';
import theme from "../../../styles/theme";

const StyledWrapper = styled.div`
  padding-top: 87px;
  text-align: center;
`;

const StyledHeader = styled.h2`
  text-align: center;
  font-weight: normal;
  font-size: 1.3rem;
  margin: 30px 0px 50px 0px;;
`;

const ListContainer = styled.div`
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.lightGray};
    content: "";
    margin-bottom: 40px;
`;

const Clickable = styled.button`
    background-color: white;
    border-color: black;
    border: 2px solid;
    margin-bottom: 20px;
    padding: 5px 15px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 0.6rem;

    color: ${props => props.active ? theme.colors.purple : 'black'};
    border-color: ${props => props.active ? theme.colors.purple : 'black'};
    transition: all 0.15s ease-in-out;

    &:hover {
      cursor: pointer;
      color: ${props => props.active ? theme.colors.white : 'black'};
      background-color: ${props => props.active ? theme.colors.purple : 'white'};
      box-shadow: 2px 2px 7px 0px rgba(0,0,0,0.2);
    }
`;

const About = ({ callback, nexusActive, prometeuszActive, medtouchActive }) => (
  <StyledWrapper>
    <StyledHeader>HLS składa się z trzech modułów</StyledHeader>
    <ListContainer>
        <Clickable active={nexusActive} onClick={() => { callback('nexus') }}>Nexus - moduł apteki szpitalnej</Clickable>
        <Clickable active={prometeuszActive} onClick={() => { callback('prometeusz') }}>Prometeusz – moduł Apteczki oddziałowej</Clickable>
        <Clickable active={medtouchActive} onClick={() => { callback('medtouch') }}>MedTouch – moduł kolektora danych</Clickable>
    </ListContainer>
    <Divider />
  </StyledWrapper>
)

export default About
