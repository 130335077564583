import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";
import About from "../components/HLS/Sections/About"
import Nexus from "../components/HLS/Sections/Nexus"
import Prometeusz from "../components/HLS/Sections/Prometeusz"
import MedTouch from "../components/HLS/Sections/MedTouch"

const HLSPage = () => {

  const [state, setState] = useState({
      nexusActive: true, 
      prometeuszActive: false,
      medtouchActive: false,
  });

  const setSectionActive = name => {
    console.log(name);

    if(name === 'nexus')
    {
      setState({
        nexusActive: true, 
        prometeuszActive: false,
        medtouchActive: false,
      });
    }
    else if (name === 'prometeusz')
    {
      setState({
        nexusActive: false, 
        prometeuszActive: true,
        medtouchActive: false,
      });
    }
    else 
    {
      setState({
        nexusActive: false, 
        prometeuszActive: false,
        medtouchActive: true,
      });
    }
  };

  return(
    <Layout>
      <SEO title="System HLS" />
      <Hero title="SYSTEM HLS"/>
      <About callback={setSectionActive} nexusActive={state.nexusActive} prometeuszActive={state.prometeuszActive} medtouchActive={state.medtouchActive} />
      <Nexus active={state.nexusActive} />
      <Prometeusz active={state.prometeuszActive} />
      <MedTouch active={state.medtouchActive} />
    </Layout>
  );
}

export default HLSPage
