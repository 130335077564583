import React from "react"

import styled from 'styled-components';
import theme from "../../../styles/theme";

import FirstPhoto from "../../../images/nexus-1.jpg"
import SecondPhoto from "../../../images/nexus-2.jpg"

import {Animated} from "react-animated-css";

const NexusContainer = styled.div`
    width: 100%;
    padding: 20px;
    min-height: 100px;
    background-color: ${theme.colors.header};

    display: ${props => props.active ? 'flex' : 'none'};
    flex-direction: column;

    p {
      font-size: 0.8rem;
    }

    img {
      width: 100%;
      height: auto;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.lightGray};
    content: "";
    margin-bottom: 40px;
`;

const FirstContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    p {
      width: 40%;
    }

    img {
      margin: 5px 10px;
    }
`;

const Nexus = ({ active }) => {
  return (
    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={active} animationInDuration={500} animationOutDuration={500}>
      <NexusContainer active={active}>
        <FirstContainer>
          <img src={FirstPhoto} alt="HLS - 1" />
          <p><strong>Nexus</strong> - System Magazynowy Apteki Szpitalnej służący do ewidencjonowania przychodów i rozchodów magazynów, pozwalający na automatyczne przyjmowanie zamówienia, automatyczne kodowanie opakowań leków indeksem własnym szpitala. Nexus to oprogramowanie dedykowane do prowadzenia Apteki Szpitalnej, dające możliwość pełnego nadzoru nad informacją o leku i artykułach medycznych w szpitalu.</p>
        </FirstContainer>
        <Divider />
        <FirstContainer>
          <p>Moduł Nexus umożliwia m. in.:

          Identyfikację artykułu poprzez zeskanowanie kodu 1D i 2D
          Jednoznaczną identyfikację użytkownika i przyznawanie dostępu zgodnie z uprawnieniami.
          Rejestrację czynności związanych z logistyką leków i art. medycznych w szpitalu,
          Obsługa magazynów wewnętrznych.
          Operacje na artykułach ponad gabarytowych
            Realizację zamówień z oddziałów szpitalnych w trybie ręcznym lub automatycznym.
            Spełnienie wymagań farmakopei XI w zakresie wytwarzania leków recepturowych i aptecznych.
            Spełnienie wymagań z zakresu raportowania do systemu ZSMOPL i KOWAL
          </p>
          <img src={SecondPhoto} alt="HLS - 2" />
        </FirstContainer>
      </NexusContainer>
    </Animated>
  );
}

export default Nexus
