import React from "react"

import styled from 'styled-components';
import theme from "../../../styles/theme";

import FirstPhoto from "../../../images/med1.jpg"
import SecondPhoto from "../../../images/med2.png"

import {Animated} from "react-animated-css";

const NexusContainer = styled.div`
    width: 100%;
    padding: 20px;
    min-height: 100px;
    background-color: ${theme.colors.header};

    display: ${props => props.active ? 'flex' : 'none'};
    flex-direction: column;

    p {
      font-size: 0.8rem;
    }

    img {
      width: 100%;
      height: auto;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.lightGray};
    content: "";
    margin-bottom: 40px;
`;

const FirstContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    p {
      width: 100%;
    }

    img {
      margin: 10px;
    }
`;

const MedTouch = ({ active }) => (
  <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={active} animationInDuration={500} animationOutDuration={500}>
  <NexusContainer active={active} >
    <FirstContainer>
      <p><strong>Medtouch</strong> – System działający w oparciu o terminal danych, nadzorujący proces potwierdzania podania leków pacjentowi m. in. poprzez weryfikację personelu, pacjenta i leków. Informacje o potwierdzeniu podania są przetwarzane i wysłane do hurtowni danych w formacie XML, który  stanowi dokument elektronicznej dokumentacji pacjenta (EDM).</p>
    </FirstContainer>
    <Divider />
    <FirstContainer>
      <img src={FirstPhoto} alt="HLS - 1" />
      <img src={SecondPhoto} alt="HLS - 2" />
    </FirstContainer>
  </NexusContainer>
  </Animated>
)

export default MedTouch
