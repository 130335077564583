import React from "react"

import styled from 'styled-components';
import theme from "../../../styles/theme";

import FirstPhoto from "../../../images/prom1.jpg"
import SecondPhoto from "../../../images/prom2.jpg"

import {Animated} from "react-animated-css";

const NexusContainer = styled.div`
    width: 100%;
    padding: 20px;
    min-height: 100px;
    background-color: ${theme.colors.header};

    display: ${props => props.active ? 'flex' : 'none'};
    flex-direction: column;

    p {
      font-size: 0.8rem;
    }

    img {
      width: 100%;
      height: auto;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.lightGray};
    content: "";
    margin-bottom: 40px;
`;

const FirstContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    p {
      width: 100%;
    }

    img {
      margin: 10px;
    }
`;

const Prometeusz = ({ active }) => (
  <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={active} animationInDuration={500} animationOutDuration={500}>
  <NexusContainer active={active} >
    <FirstContainer>
    <p><strong>Prometeusz</strong> to System Apteczki Oddziałowej zainstalowany w Zautomatyzowanym Punkcie Dystrybucji Leków, który ma zastosowanie na oddziałach szpitalnych. Leki przechowywane w Punkcie Dystrybucji Leków poddawane są ścisłej kontroli rozchodów. Dodatkowo monitorowane są również parametry przechowywania (np. wilgotność, temperatura) w oparciu, o które tworzone są kompleksowe metryki leków.
    System nadzoruje sposób kompletacji leków na pacjenta, daty ważności leków, numery serii opakowań, indywidualne pojemniki lekowe oraz iniekcyjne, leki własne pacjenta oraz zestaw przeciwwstrząsow.</p>
    </FirstContainer>
    <Divider />
    <FirstContainer>
      <img src={FirstPhoto} alt="HLS - 1" />
      <img src={SecondPhoto} alt="HLS - 2" />
    </FirstContainer>
  </NexusContainer>
  </Animated>
)

export default Prometeusz
